<template>
   <v-container 
      style="max-width:2600px;height:100%;height:100%;background-image: url('/imgs/light_bg3.jpg');background-size:cover;"
      class="pa-0">

      <v-row style="height:100%;background-color:#00000055;" class="ma-0 pa-0">
         <v-col style="height:100%;" 
            class="ma-0 pa-0 pl-6" align="left" justify="center">
            <div style="min-width:700px;width:100%;height:100%;position:relative;color:white;font-size:14px;">
               <div class="pl-0 mb-1 mt-1" style="font-weight:700;color:cyan;border-bottom:1px solid cyan;">
                  <div style="display:inline-block;width:140px;font-weight:400;"
                     class="pl-4">
                  Name</div>
                  <div style="display:inline-block;width:80px;font-weight:400;">
                  Status</div>
                  <div style="display:inline-block;width:160px;font-weight:400;">
                  Mode</div>
                  <div style="display:inline-block;width:80px;font-weight:400;">
                  Button</div>
                  <div style="display:inline-block;width:80px;font-weight:400;">
                  Control</div>
               </div>
               <div class="pl-0 py-1" v-for="(light, index) in lights" :key="index">
                  <div style="display:inline-block;width:140px;font-weight:400;"
                     class="pl-4">
                  {{light.name}}</div>
                  <div style="display:inline-block;width:80px;font-weight:400;">
                     <span v-if="light.status.value">🟢</span>
                     <span v-else>⚫️</span>
                  </div>

                  <div style="display:inline-block;width:160px;font-weight:400;">
                     <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           text
                           dark
                           v-bind="attrs"
                           v-on="on"
                           :color="resolveLightModeColor(light.mode.value)"
                           class="text-none px-1"
                           style="min-width:30px;"
                        >
                           {{resolveLightModeText(light.mode.value)}}
                        </v-btn>
                        </template>
                        <v-list>
                           <v-list-item-group>
                              <v-list-item @click="callSetPoint(light.mode.pointName, 0)">
                                 <v-list-item-title>Tự do</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="callSetPoint(light.mode.pointName, 1)">
                                 <v-list-item-title>Giữ luôn mở</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="callSetPoint(light.mode.pointName, -1)">
                                 <v-list-item-title>Giữ luôn tắt</v-list-item-title>
                              </v-list-item>
                           </v-list-item-group>
                        </v-list>
                     </v-menu>
                  </div>

                  <div style="display:inline-block;width:80px;font-weight:400;">
                  {{light.button.value}}</div>
                  <div style="display:inline-block;width:180px;font-weight:400;">
                     <template v-if="light.mode.value==0">
                        <v-btn v-if="!light.status.value"
                           outlined
                           dark
                           class="text-none px-2"
                           style="min-width:30px;"
                           color="#76FF03"
                           @click="turnOnLight(light.exCtrl.pointName)"
                        >
                           Mở đèn
                        </v-btn>

                        <v-btn v-else
                           outlined
                           dark
                           class="text-none px-2"
                           style="min-width:30px;"
                           color="#FFFFFF"
                           @click="turnOffLight(light.exCtrl.pointName)"
                        >
                           Tắt đèn
                        </v-btn>
                     </template>
                  </div>
               </div>
            </div>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
export default {
   data () {
      return {
         socketAskKey: new Date().getTime(),
         records: [],
         lights: [
            {
               name: "light_bv_L1",
               status: {pointName: 'light_bv_L1', value: null},
               exCtrl: {pointName: 'light_bv_L1_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L1_mode', value: null},
               button: {pointName: 'light_bv_L1_btn', value: null},
            },
            {
               name: "light_bv_L2",
               status: {pointName: 'light_bv_L2', value: null},
               exCtrl: {pointName: 'light_bv_L2_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L2_mode', value: null},
               button: {pointName: 'light_bv_L2_btn', value: null},
            },
            {
               name: "light_bv_L3",
               status: {pointName: 'light_bv_L3', value: null},
               exCtrl: {pointName: 'light_bv_L3_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L3_mode', value: null},
               button: {pointName: 'light_bv_L3_btn', value: null},
            },
            {
               name: "light_bv_L4",
               status: {pointName: 'light_bv_L4', value: null},
               exCtrl: {pointName: 'light_bv_L4_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L4_mode', value: null},
               button: {pointName: 'light_bv_L4_btn', value: null},
            },
            {
               name: "light_bv_L5",
               status: {pointName: 'light_bv_L5', value: null},
               exCtrl: {pointName: 'light_bv_L5_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L5_mode', value: null},
               button: {pointName: 'light_bv_L5_btn', value: null},
            },
            {
               name: "light_bv_L6",
               status: {pointName: 'light_bv_L6', value: null},
               exCtrl: {pointName: 'light_bv_L6_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L6_mode', value: null},
               button: {pointName: 'light_bv_L6_btn', value: null},
            },
            {
               name: "light_bv_L7",
               status: {pointName: 'light_bv_L7', value: null},
               exCtrl: {pointName: 'light_bv_L7_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L7_mode', value: null},
               button: {pointName: 'light_bv_L7_btn', value: null},
            },
            {
               name: "light_bv_L8",
               status: {pointName: 'light_bv_L8', value: null},
               exCtrl: {pointName: 'light_bv_L8_btn_exctrl', value: null},
               mode: {pointName: 'light_bv_L8_mode', value: null},
               button: {pointName: 'light_bv_L8_btn', value: null},
            },
            {
               name: "light_gxe_L1",
               status: {pointName: 'light_gxe_L1', value: null},
               exCtrl: {pointName: 'light_gxe_L1_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L1_mode', value: null},
               button: {pointName: 'light_gxe_L1_btn', value: null},
            },
            {
               name: "light_gxe_L2",
               status: {pointName: 'light_gxe_L2', value: null},
               exCtrl: {pointName: 'light_gxe_L2_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L2_mode', value: null},
               button: {pointName: 'light_gxe_L2_btn', value: null},
            },
            {
               name: "light_gxe_L3",
               status: {pointName: 'light_gxe_L3', value: null},
               exCtrl: {pointName: 'light_gxe_L3_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L3_mode', value: null},
               button: {pointName: 'light_gxe_L3_btn', value: null},
            },
            {
               name: "light_gxe_L4",
               status: {pointName: 'light_gxe_L4', value: null},
               exCtrl: {pointName: 'light_gxe_L4_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L4_mode', value: null},
               button: {pointName: 'light_gxe_L4_btn', value: null},
            },
            {
               name: "light_gxe_L5",
               status: {pointName: 'light_gxe_L5', value: null},
               exCtrl: {pointName: 'light_gxe_L5_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L5_mode', value: null},
               button: {pointName: 'light_gxe_L5_btn', value: null},
            },
            {
               name: "light_gxe_L6",
               status: {pointName: 'light_gxe_L6', value: null},
               exCtrl: {pointName: 'light_gxe_L6_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L6_mode', value: null},
               button: {pointName: 'light_gxe_L6_btn', value: null},
            },
            {
               name: "light_gxe_L7",
               status: {pointName: 'light_gxe_L7', value: null},
               exCtrl: {pointName: 'light_gxe_L7_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L7_mode', value: null},
               button: {pointName: 'light_gxe_L7_btn', value: null},
            },
            {
               name: "light_gxe_L8",
               status: {pointName: 'light_gxe_L8', value: null},
               exCtrl: {pointName: 'light_gxe_L8_btn_exctrl', value: null},
               mode: {pointName: 'light_gxe_L8_mode', value: null},
               button: {pointName: 'light_gxe_L8_btn', value: null},
            },
             {
               name: "light_vp_L1",
               status: {pointName: 'light_vp_L1', value: null},
               exCtrl: {pointName: 'light_vp_L1_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L1_mode', value: null},
               button: {pointName: 'light_vp_L1_btn', value: null},
            },
            {
               name: "light_vp_L2",
               status: {pointName: 'light_vp_L2', value: null},
               exCtrl: {pointName: 'light_vp_L2_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L2_mode', value: null},
               button: {pointName: 'light_vp_L2_btn', value: null},
            },
            {
               name: "light_vp_L3",
               status: {pointName: 'light_vp_L3', value: null},
               exCtrl: {pointName: 'light_vp_L3_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L3_mode', value: null},
               button: {pointName: 'light_vp_L3_btn', value: null},
            },
            {
               name: "light_vp_L4",
               status: {pointName: 'light_vp_L4', value: null},
               exCtrl: {pointName: 'light_vp_L4_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L4_mode', value: null},
               button: {pointName: 'light_vp_L4_btn', value: null},
            },
            {
               name: "light_vp_L5",
               status: {pointName: 'light_vp_L5', value: null},
               exCtrl: {pointName: 'light_vp_L5_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L5_mode', value: null},
               button: {pointName: 'light_vp_L5_btn', value: null},
            },
            {
               name: "light_vp_L6",
               status: {pointName: 'light_vp_L6', value: null},
               exCtrl: {pointName: 'light_vp_L6_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L6_mode', value: null},
               button: {pointName: 'light_vp_L6_btn', value: null},
            },
            {
               name: "light_vp_L7",
               status: {pointName: 'light_vp_L7', value: null},
               exCtrl: {pointName: 'light_vp_L7_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L7_mode', value: null},
               button: {pointName: 'light_vp_L7_btn', value: null},
            },
            {
               name: "light_vp_L8",
               status: {pointName: 'light_vp_L8', value: null},
               exCtrl: {pointName: 'light_vp_L8_btn_exctrl', value: null},
               mode: {pointName: 'light_vp_L8_mode', value: null},
               button: {pointName: 'light_vp_L8_btn', value: null},
            },
         ]
      }
   },
   sockets: {
      reportLastValue: function (payload) {
         if(payload && payload.devCode == this.$root.devCode){
            this.records = payload.content

            // console.log(this.records)

            this.lights.forEach(light => {
               this.records.forEach(rec => {
                  if(rec.reportName == light.status.pointName) {
                     light.status.value = rec.value
                  }
                  if(rec.reportName == light.exCtrl.pointName) {
                     light.exCtrl.value = rec.value
                  }
                  if(rec.reportName == light.mode.pointName) {
                     light.mode.value = rec.value
                  }
                  if(rec.reportName == light.button.pointName) {
                     light.button.value = rec.value
                  }
               })
            })

            this.$forceUpdate()
         }

      },
      callHTTPRequestResult: function (payload) {
         console.log("callHTTPRequestResult")
         console.log(payload)
         if(payload && payload.result && payload.result.status){
            if(payload.result.status.startsWith("ERR")){
               alert(payload.result.message)
            }
         }
      }
   },
   methods: {
      requestLastData(){
         this.$socket.emit('requestLastValue', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey
         })
      },
      resolveLightModeText(mode){
         if(mode==0) return "Tự do"
         if(mode==-1) return "Giữ luôn tắt"
         if(mode==1) return "Giữ luôn mở"
         return ""
      },
      resolveLightModeColor(mode){
         if(mode==-1) return "#BDBDBD"
         if(mode==1) return "#76FF03"
         return "white"
      },
      turnOnLight (reportName) {
         let self = this
         self.callSetPoint(reportName, 0)
         setTimeout(function(){
            self.callSetPoint(reportName, 1)
         }, 500)
      },
      turnOffLight (reportName) {
         let self = this
         self.callSetPoint(reportName, 1)
         setTimeout(function(){
            self.callSetPoint(reportName, 0)
         }, 500)
      },
      callSetPoint (reportName, value){
         let path = this.$root.apiGate+ 'setPoints'
         let payload = {
            points: [
               {
                  reportName: reportName,
                  value: value
               }
            ]
         }
         this.callApiOverOTA("POST", path, payload)
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
         this.requestLastDataAfter1Sec()
      },
      requestLastDataAfter1Sec(){
         let self = this
         setTimeout(function(){
            self.requestLastData()
         }, 1000)
      }
   },
   onmounted () {
      this.requestLastData()
   }
}
</script>

<style>

</style>